<template>
    <div>
        <div class="box">
            <div class="top-left">
                <div class="title">
                    <h1>订单详情</h1>
                </div>
                <div class="content-left">
                    <div class="item">
                        <div class="item-left" >订单ID</div>
                        <div>{{sonorderDetails.id}}</div>
                    </div>
                    <div class="item">
                        <div class="item-left">订单状态</div>
                        <div>{{sonorderDetails.status}}</div>
                        <!-- <font v-if="sonorderDetails.status=='购买失败'" color="#828494">购买失败</font> 
                        <font v-if="sonorderDetails.status=='待审核'" color="#1A54EC">待审核</font>
                        <font v-if="sonorderDetails.status=='已通过'" color="#FF6056">已通过</font>
                        <font v-if="sonorderDetails.status=='已驳回'" color="#FE8D07">已驳回</font>
                        <font v-if="sonorderDetails.status=='待提交'" color="#1A54EC">待提交</font>
                        <font v-if="sonorderDetails.status=='已过期'" color="#828494">已过期</font>
                        <font v-if="sonorderDetails.status=='已完成'" color="#18B528">已完成</font>
                        <font v-if="sonorderDetails.status=='待结算'" color="#1A54EC">待结算</font>
                        <font v-if="sonorderDetails.status=='申诉中'" color="#FE8D07">申诉中</font> -->
                    </div>
                    <div class="item">
                        <div class="item-left" >下单用户</div>
                        <div>{{sonorderDetails.nickname}}</div>
                    </div>
                    <div class="item">
                        <div class="item-left" >提交订单号</div>
                        <div>{{sonorderDetails.retailer_item_id}}</div>
                    </div>
                    <div class="item">
                        <div class="item-left" >提交截图</div>
                        <div>{{sonorderDetails.image_info.count}}张</div>
                        <div style="margin-left:53px;color:#828494;" v-if="sontime">上传时间：{{sontime | dateFormat}}</div>
                    </div>
                    <div class="item">
                        <div class="item-left">实返金额</div>
                        <div>￥{{sonorderDetails.refund_amount}}</div>
                    </div>
                    <div class="item">
                        <div class="item-left" >返款状态</div>
                        <div v-if="sonorderDetails.refund_status==1">待返款</div>
                        <div v-else>已返款</div>
                    </div>
                    <div class="item">
                        <div class="item-left">扣款/驳回原因</div>
                        <div>{{sonorderDetails.defect_reason}}</div>
                    </div>
                    <div class="item">
                        <div class="item-left" >申诉原因</div>
                        <div v-if="sonorderDetails.appeal_reason==null" style="color:#828494;">未申诉</div>
                        <div v-else style="color:#828494;">{{sonorderDetails.appeal_reason}}</div>
                    </div>
                    <div class="item">
                        <div class="item-left" >任务时间轴</div>
                        <div>
                            <el-timeline>
                                <el-timeline-item
                                v-for="(item, index) in sonorderDetails.time_axis"
                                :key="index"
                                :timestamp="item.time | dateFormat">
                                <div v-if="item.action==1">创建成功</div>
                                <div v-if="item.action==2">提交截图</div>
                                <div v-if="item.action==3">审核通过</div>
                                <div v-if="item.action==4">商家驳回</div>
                                <div v-if="item.action==5">用户申诉</div>
                                <div v-if="item.action==6">申诉成功</div>
                                <div v-if="item.action==7">申诉失败</div>
                                <div v-if="item.action==8">订单过期</div>
                                <div v-if="item.action==9">驳回超时</div>
                                <div v-if="item.action==10">返款成功</div>
                                <div v-if="item.action==11">下单成功"</div>
                                </el-timeline-item>
                            </el-timeline>
                        </div>
                    </div>
                </div>
            </div>
            <div class="top-right">
                <div class="title">
                    <h1>任务截图</h1>
                </div>
                <div class="content-right">
                    <div  v-for="(item,index) in sonsrcList" :key="index">
                        <el-image :src="item" :preview-src-list="sonsrcList" :z-index="9999"></el-image>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-box">
            <div class="title">
                <h1>任务信息</h1>
            </div>
            <div class="content-left">
                <div class="item">
                    <div class="item-left" >返还金额</div>
                    <div class="fw">
                        订单满￥{{sonorderDetails.task_info.explain_info.ask_amount}}返￥{{sonorderDetails.task_info.explain_info.ask_refund}}；
                        <span v-if="sonorderDetails.task_info.explain_info.type==1">不满返￥{{sonorderDetails.task_info.explain_info.set_refund}}</span>
                        <span v-if="sonorderDetails.task_info.explain_info.type==2">不满不返</span>
                        <span v-if="sonorderDetails.task_info.explain_info.type==3">按实付返</span>
                    </div>
                </div>
                <!-- <div class="item">
                    <div class="item-left" >任务开始时间</div>
                    <div class="fw">{{sonorderDetails.task_info.start_date}}</div>
                </div> -->
                <div class="item">
                    <div class="item-left" >任务名额</div>
                    <div class="fw">{{sonorderDetails.task_info.quota}}个</div>
                </div>
                <!-- <div class="item">
                    <div class="item-left" >任务展现时段</div>
                    <div class="fw" v-for="(item,index) in sonorderDetails.task_info.release_times" :key="index">
                        <div style="margin-right:20px;">
                            {{item.start_time}} 至 {{item.end_time}} 
                        </div>
                    </div>
                </div> -->
                <div class="item">
                    <div class="item-left" >评价星级</div>
                    <div class="fw">{{sonorderDetails.task_info.explain_info.star}}</div>
                </div>
                <div class="item">
                    <div class="item-left" >评价字数</div>
                    <div class="fw">{{sonorderDetails.task_info.explain_info.word_count}}</div>
                </div>
                <div class="item">
                    <div class="item-left" >评价图片</div>
                    <div class="fw">{{sonorderDetails.task_info.explain_info.image}}</div>
                </div>
                <div class="item">
                    <div class="item-left" >指定菜品</div>
                    <div class="fw" v-if="!sonorderDetails.task_info.explain_info.menus[0]">无</div>
                    <div class="fw" v-for="(item,index) in sonorderDetails.task_info.explain_info.menus" :key="index" style="margin-right:20px"></div>
                </div>
                <div class="item">
                    <div class="item-left" >备注</div>
                    <div class="fw">{{sonorderDetails.task_info.explain_info.desc}}</div>
                </div>
                <div class="item">
                    <div class="item-left" >任务ID</div>
                    <div class="fw">{{sonorderDetails.task_info.id}}</div>
                </div>
                <!-- <div class="item">
                    <div class="item-left" >任务创建时间</div>
                    <div class="fw">{{sonorderDetails.task_info.start_date}}</div>
                </div>
                <div class="item">
                    <div class="item-left" >预付保证金</div>
                    <div class="fw">￥{{sonorderDetails.task_info.bond}}</div>
                </div>
                <div class="item">
                    <div class="item-left" >预付佣金</div>
                    <div class="fw">￥{{sonorderDetails.task_info.bond}}</div>
                </div>
                <div class="item">
                    <div class="item-left" >任务状态</div>
                    <div class="fw" style="color:#1A54EC;"> {{sonorderDetails.task_info.status}}</div>
                </div>
                <div class="item">
                    <div class="item-left" >自动续期</div>
                    <div class="fw">{{sonorderDetails.task_info.is_renewal}}</div>
                </div>
                <div class="item">
                    <div class="item-left" >任务完成情况</div>
                    <div class="fw">待提交 {{sonorderDetails.task_info.progress.submit}} 单，待审核 {{sonorderDetails.task_info.progress.check}} 单，已驳回 {{sonorderDetails.task_info.progress.reject}} 单，待结算 {{sonorderDetails.task_info.progress.unsettled}} 单，已结算 {{sonorderDetails.task_info.progress.settled}} 单</div>
                </div> -->
                <div class="item">
                    <div class="item-left" >任务来源</div>
                    <div class="fw" style="line-height:30px">所属商户：{{sonorderDetails.source.retailer_name}} (商户ID：{{sonorderDetails.source.retailer_id}}) 
                        <!-- <br/>{{sonorderDetails.source.channel_name}}（渠道ID：{{sonorderDetails.source.channel_id}}） -->
                    </div>
                </div>
                <div class="item">
                    <div class="item-left">绑定规则</div>
                    <div class="fw" style="line-height:30px">
                        <!-- 规则名称：{{sonorderDetails.task_info.rule.name}}<br/>
                        版本号：{{sonorderDetails.task_info.rule.ver}}<br/>
                        渠道费率：￥{{sonorderDetails.task_info.rule.rate}}<br/> -->
                        用户提交时限：{{sonorderDetails.task_info.rule.submit_limit_time}}小时<br/>
                        商家审核时限：{{sonorderDetails.task_info.rule.check_limit_time}}小时<br/>
                        用户申诉时限：{{sonorderDetails.task_info.rule.appeal_limit_time}}小时<br/>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
export default {
    props:['sonorderDetails','sonsrcList','sontime'],
    data(){
        return{
        }
    },
    methods:{
        top(){
            parent.window.scrollTo(0, 0);
        }
    }
}
</script>
<style lang="less" scoped>
    .box{
        display: flex;
        // overflow-y: scroll;
    }
    .top-left{
        position: relative;
        width: 729px;
        height: 1000px;
    }
    .top-left::after{
        content: '';
        height: 922px;
        width: 1px;
        background-color: #BABEC7;
        display: inline-block; 
        position: absolute;
        right: 0;
        top: 80px;
    }
    .top-right{
        width: 380px;
        height: 1000px;
    }
    .title{
        position: relative;
        width: 162px;
        display: flex;
        margin-left: 40px;
        margin-top: 20px;
        justify-content: center;
        h1{
            font-size: 14px;
        }
    }
    .content-left{
        margin-left: 50px;
        margin-top: 15px;
    }
    .title::after{
        content: '';
        position: absolute;
        bottom: 0;
        width: 162px;
        height: 2px;
        background-image: linear-gradient(to right, #FF6056 , #ffffff);
    }
    .item{
        padding: 20px 0;
        display: flex;
    }
    .item-left{
        display: flex;
        justify-content: flex-end;
        color:#828494;
        width:110px;
        margin-right:30px;
        font-size: 16px;
    }
    .content-right{
        margin-top: 20px;
        overflow-y: scroll;
        height: 900px;
        width: 162px;
        padding-left: 80px;
        padding-right: 120px;
    }
    .content-right::-webkit-scrollbar {
        /*滚动条整体样式*/
        width : 6px;  /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
    }
    .content-right::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        // bradius: 10px;
        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
        background   : #BABEC7;
    }
    .content-right::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
        // bradius: 10px;
        background   : #ededed;
    }
    .fw{
        font-weight: 700;
    }
</style>