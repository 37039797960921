<template>
    <!-- 用户页面 -->
    <div class="box">
        <div class="header">
            <div class="info">
                <!-- <el-avatar :size="66" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar> -->
                <div class="user-info">
                    <div class="user-name">
                        {{name}}
                        <!-- <span>（普通会员）</span> -->
                    </div>
                    <div class="user-id">ID：{{id}}</div>
                </div>
            </div>
            <!-- 面包屑 -->
            <!-- <el-breadcrumb 
                separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/UserManagement' }">
                    用户管理
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    {{crumb}}
                </el-breadcrumb-item>
            </el-breadcrumb> -->
            <search :sonPlaceholder="placeholder" @sonsearchBut="searchBut" style="height:40px;margin-left:150px;min-width:350px;"/>
            <datePicker @selector="selector"/>
        </div>
        <div class="tabs">
            <div :class="['tab',tabIndex==index?'active':'']" v-for="(item,index) in tabs" :key="index" @click="tabBtn(index)">
                {{item}}
            </div>
        </div>
        <div class="content">
            <!-- 订单记录 -->
            <div class="record-table" v-if="tabIndex==0">
                <el-table 
                    :data="orderList" 
                    style="width: 100%;" 
                    height="calc(100vh - 300px)"  
                    ref="table"
                    v-loading="record_loading"
                    element-loading-text="拼命加载中">
                        <el-table-column
                            fixed
                            align="center"
                            type="index"
                            label="序号"
                            width="58">
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="trade_no"
                            label="订单ID">
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="order_type"
                            label="订单类型">
                            <template slot-scope="scope">
                                <span v-if="scope.row.order_type=='OnlineProduct'">在线点餐订单</span>
                                <span v-else-if="scope.row.order_type=='CardVoucher'">卡券订单</span>
                                <span v-else-if="scope.row.order_type=='RechargeProduct'">直充订单</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="brand_name"
                            label="所属品牌">
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="created_time"
                            label="订单创建时间">
                            <template slot-scope="scope">
                                {{scope.row.created_time | dateFormat}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="user_id"
                            label="下单用户ID">
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="amount"
                            label="用户实付">
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="status"
                            label="订单状态">
                            <template slot-scope="scope">
                                <span v-if="scope.row.status=='0'">已出售</span>
                                <span v-else-if="scope.row.status=='1'">已完成</span>
                                <span v-else-if="scope.row.status=='2'">已退款</span>
                                <span v-else-if="scope.row.status=='3'">售后中</span>
                                <span v-else-if="scope.row.status=='RECHARGE'">充值中</span>
                                <span v-else-if="scope.row.status=='SUCCESS'">成功</span>
                                <span v-else-if="scope.row.status=='FAILURE'">失败</span>
                                <span v-else-if="scope.row.status=='WAIT_OUT_MEAL'">待出餐</span>
                                <span v-else-if="scope.row.status=='OUT_MEAL'">已出餐</span>
                                <span v-else-if="scope.row.status=='FAIL'">订单失败,出餐失败</span>
                            </template>
                        </el-table-column>
                </el-table>
                <!-- 分页 -->
                <div class="footer">
                    <paging :sonpagingData="pagingData" @sonHandleSizeChange="handleSizeChange" @sonHandleCurrentChange="handleCurrentChange" />
                </div>
            </div>
        </div>
        <el-drawer :visible.sync="drawer" :with-header="false" :size="1136" @close="handleClose">
            <orderParticulars :sonorderDetails="orderDetails" :sonsrcList="srcList"/>
        </el-drawer>
    </div>
</template>
<script>
import search from '../../components/search.vue'
import paging from '../../components/paging.vue'
import datePicker from '../../components/datePicker.vue'
import orderParticulars from '../../components/orderParticulars.vue'
export default {
    components:{
        search,
        paging,
        datePicker,
        orderParticulars
    },
    data() {
        return {   
            name:'',
            id:'',
            // 分页数据
            pagingData:{
                page:1,
                max_page:15,
                count:0,
            },
            search:'',// 搜索值
            start_time:'',// 开始时间
            end_time:'',// 结束时间
            placeholder:'搜索订单ID/提交订单号', // 搜索提示
            drawer:false, // 订单详情的抽柜显示状态

            orderList:[],// 订单列表

            orderDetails:{},// 订单详情数据
            srcList:[],// 图片数组
            loading:true,//  加载状态
            tabs:['订单记录'],
            tabIndex:0,
            //--------------------订单记录------------------
            recordList:[{}],
            record_loading:false,
        }
    },
    created(){
        this.getorderList()
        console.log(this.$route)
        this.name=this.$route.query.username
        this.id=this.$route.params.id
        // this.crumb=this.$route.query.username
    },
    updated(){
        this.$refs.table.doLayout()
    },
    methods:{
        tabBtn(index){
            this.tabIndex=index
        },
        // 接收搜索组件传递过来的值
        searchBut(val){
            console.log(val)
            this.search=val
            this.getorderList()
        },
        // 接收日期选择的数据
        selector(data){
            this.start_time=data.start_time
            this.end_time=data.end_time
            this.getorderList()
        },
        // 监听pagesize改变
        handleSizeChange(val) {
            console.log(val)
            this.pagingData.max_page=val
            this.getorderList()
        },
        // 监听页码值
        handleCurrentChange(val) {
            console.log(val)
            this.pagingData.page=val
            this.getorderList()
        },
        // 获取订单列表请求
        async getorderList(){
            this.loading=true
            const {data:res} = await this.$http.get('/tq/user_manager_order_record_list/',{
                params:{
                    page:this.pagingData.page,
                    max_page:this.pagingData.max_page,
                    uid:this.$route.params.id,
                    search:this.search,
                    start_time:this.start_time,
                    end_time:this.end_time,
                }
            })
            if(res.status!==200) return this.$message({ showClose: true, message: res.message, type: 'error'})
            this.orderList=res.data.data
            this.pagingData.count=res.data.total
            this.loading=false
        },
        //点击查看
        async skipTo(id){
            const{data:res} = await this.$http.get('c-admin/order_info/',{
                params:{
                    order_id:id
                }
            })
            console.log(res)
            if(res.status!==200) return this.$message({ showClose: true, message: '获取订单详情失败', type: 'error'})
            // 获取子组件的数据
            this.orderDetails=res.data
            //获取图片数组
            res.data.image_info.images.forEach((val, index) =>{
                this.srcList.push(val.images_url)
            });
            // 显示抽柜
            this.drawer=true
        },
        // 关闭抽柜回调
        handleClose(){
            // this.orderDetails={}
            this.srcList=[]
        },
    }
}
</script>
<style lang="less" scoped>
    .box{
        padding: 0 25px;
    }
    .header{
        height: 68px;
        display: flex;
        align-items: center;
        white-space:nowrap;
        .info{
            display: flex;
            align-items: center;
            .user-info{
                line-height: 1.7;
                margin-left: 10px;
                .user-name{
                    font-size: 18px;
                    color: #252530;
                    font-weight: 700;
                    span{
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
                div:last-child{
                    font-size: 14px;
                    color: #252530;
                }
            }
        }
    }
    .content{
        padding-top: 20px;
        height: calc(100vh - 280px);
    }
    /deep/.el-drawer__body {
        overflow-y: scroll
    }
    /deep/.el-input-group__append{
        height: 40px;
    }
    .tabs{
        display: flex;
        margin-top: 22px;
        .tab{
            text-align: center;
            width: 200px;
            position: relative;
        }
        .active::before{
            content: '';
            width: 16px;
            height: 4px;
            background: #FE8D07;
            border-radius: 21px;
            position: absolute;
            top: 25px;
            left: 50%;
            transform: translate(-50%, );
        }
    }
.footer{
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>